import PageLayout from "../../layouts/PageLayout";
import React, {useContext, useEffect, useState} from "react";
import callToAPI from "../../api";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {TranslatorContext} from "../../context/Translator";
import {LabelFieldComponent} from "../../components/fields";
import {ButtonComponent} from "../../components/elements";
import Modal from "react-bootstrap/Modal";

const CategoriesPage = () => {
	const [categoryList, setCategoryList] = useState([]);
	const [error, setError] = useState(null);
	const { t } = useContext(TranslatorContext);
	const [alertModal, setAlertModal] = useState(false)
	const [deleteCategoryID, setDeleteCategoryID] = useState('')
	useEffect(() => {
		callToAPI('/categories/get', 'get')
			.then(res => setCategoryList(res))
			.catch(err => setError(err.message));
	}, []);
	const deleteCategory = async () => {
		setAlertModal(false);
		await callToAPI(`/admin/category/delete/${deleteCategoryID}`, 'delete')
	}
	const createCategory = async (category) => {
		const response = callToAPI('/category/create', 'post', {...category})
		const data = await response.json();
		return data;
	}

	const handleAddCategory = async (newCategory) => {
		try {
			const createdCategory = await createCategory(newCategory);
			setCategoryList([...categoryList, createdCategory]);
		} catch (err) {
			setError(err.message);
		}
	};

	const CategoryNode = ({ category }) => {
		const [showForm, setShowForm] = useState(false);
		const subCategories = categoryList.filter(cat => cat.parentID === category._id);

		const handleAddSubCategory = (e, parentID) => {
			e.preventDefault();
			const categoryName = e.target.categoryName.value;
			const newCategory = {
				categoryName,
				parentID
			};
			handleAddCategory(newCategory);
			e.target.categoryName.value = '';
			setShowForm(false);
		};

		return (
			<div className="ms-5 mt-2 mb-2">
				<div>
					{category.categoryName}
					<ButtonComponent type="submit" title="Add Subcategory"  className="mc-btn ms-2 primary pt-2 pb-2"  icon="add_circle" onClick={() => setShowForm(!showForm)}>{ t('Add') }</ButtonComponent>
					<ButtonComponent type="submit" title="Add Subcategory"  className="ms-2 mc-btn bg-danger delete pt-2 pb-2"  icon="close" onClick={() => {
						setAlertModal(true);
						setDeleteCategoryID(category._id);
					}}>{ t('Remove') }</ButtonComponent>
				</div>
				{showForm && (
					<form onSubmit={(e) => handleAddSubCategory(e, category._id)} className="d-flex align-items-end">
						<LabelFieldComponent type="text" label="Subcategory name" name="categoryName" required/>
						<ButtonComponent type="submit" title="Add root category"  className="mc-btn ms-2 primary pt-2 pb-2"  icon="add_circle">{ t('Add') }</ButtonComponent>
					</form>
				)}
				{subCategories.length > 0 && (
					<div>
						{subCategories.map(subCategory => (
							<CategoryNode key={subCategory._id} category={subCategory} />
						))}
					</div>
				)}
			</div>
		);
	};

	const CategoryForm = () => {
		const handleAddRootCategory = (e) => {
			e.preventDefault();
			const categoryName = e.target.categoryName.value;
			const newCategory = {
				categoryName,
				parentID: "0"
			};
			handleAddCategory(newCategory);
			e.target.categoryName.value = '';
		};

		return (
			<form onSubmit={handleAddRootCategory}>
				<div className="d-flex align-items-end">
					<label className="me-2">
						<LabelFieldComponent type="text" label="Category name" name="categoryName" required/>
					</label>
					<ButtonComponent type="submit" title="Add root category"  className="mc-btn primary"  icon="add_circle">{ t('Add Root Category') }</ButtonComponent>
				</div>
			</form>
		);
	};

	const rootCategories = categoryList.filter(category => category.parentID === "0");

	return (
		<PageLayout>
			<Row>
				<Col xl={12}>
					<div className="mc-card mb-4">
						<div className='mc-breadcrumb'>
							<h3 className="mc-breadcrumb-title">{t('Categories')}</h3>
							<ul className="mc-breadcrumb-list">
								<li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('home')}</Link></li>
								<li className="mc-breadcrumb-item">{t('Categories')}</li>
							</ul>
						</div>
					</div>
				</Col>
				<Col xl={12}>
					<div className="mc-card">
						{error && <div style={{ color: 'red' }}>{error}</div>}
						<CategoryForm />
						<div>
							{rootCategories.map(category => (
								<CategoryNode key={category._id} category={category} />
							))}
						</div>
					</div>
				</Col>
			</Row>
			<Modal show={ alertModal } onHide={()=> setAlertModal(false)}>
				<div className="mc-alert-modal">
					<i className="material-icons">new_releases</i>
					<h3>are your sure!</h3>
					<p>Want to delete this category? {deleteCategoryID}</p>
					<Modal.Footer>
						<ButtonComponent type="button" className="btn btn-secondary" onClick={()=> setAlertModal(false)}>{t('close')}</ButtonComponent>
						<ButtonComponent type="button" className="btn btn-danger" onClick={deleteCategory}>{t('delete')}</ButtonComponent>
					</Modal.Footer>
				</div>
			</Modal>
		</PageLayout>
	);
}

export default CategoriesPage;