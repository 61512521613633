import Cookies from 'js-cookie'

const BASE_URL = 'http://localhost:8080/admin'
// const BASE_URL = 'https://api-zabawix-bca322529e63.herokuapp.com'

const adminCallToAPI = async (url, method = 'get', body = null, responseType = 'json', formData = false) => {
	const token = Cookies.get("token");
	console.log(token)
	const config = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			"Access-Control-Allow-Credentials": true,
			"Access-Control-Allow-Headers": '*'
		},
	}

	if(!formData){
		config.headers['Content-Type'] = 'application/json';
	}

	if (token) {
		config.headers['Authorization'] = `${token}`;
	}

	if(body){
		config.body = formData ? body : JSON.stringify(body);
	}

	const response = await fetch(BASE_URL + url, config);

	if (!response.ok) {
		const error = await response.json();
		throw new Error(error.message || 'Something went wrong');
	}

	if(responseType === 'json'){
		const data = await response.json();
		return await data;
	}
	const data = await response.text();
	return await data;
}

export default adminCallToAPI;