import React, {useContext, useEffect, useState} from "react";
import { TranslatorContext } from "../../context/Translator";
import { Link } from "react-router-dom";
import { Row, Col, Dropdown } from "react-bootstrap";
import { FloatCardComponent } from "../../components/cards";
import { PaginationComponent } from "../../components";
import LabelFieldComponent from "../../components/fields/LabelFieldComponent";
import UsersTableComponent from "../../components/tables/UsersTableComponent";
import PageLayout from "../../layouts/PageLayout";
import floats from "../../assets/data/floats.json"
import adminCallToAPI from "../../admin-api";

export default function UserListPage() {

    const { t } = useContext(TranslatorContext);
    const [users, setUsers] = useState({
        thead: ["role", "email", "password", "status", "created", "action"],
        tbody: [

        ]
    })
    useEffect(() => {
        adminCallToAPI('/users/get', 'get')
            .then(res => {
                const usersData = res.map(user => {
                    
                    return{
                        id: user._id,
                        role: user.accountType === 'admin' ? { "icon": "admin", "text": "Admin" } : { "icon": "person", "text": "User" },
                        email: user.email,
                        password: user.password,
                        status: user.status,
                        created: user.createdAt,
                        action: { "edit": "edit", "block": "block", "view": "visibility" }
                    }
                })
                setUsers(prevState => {
                    return{
                        thead: prevState.thead,
                        tbody: usersData
                    }
                })
            });
    }, []);

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <div className="mc-card">
                        <div className='mc-breadcrumb'>
                            <h3 className="mc-breadcrumb-title">{t('user_list')}</h3>
                            <ul className="mc-breadcrumb-list">
                                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('home')}</Link></li>
                                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('users')}</Link></li>
                                <li className="mc-breadcrumb-item">{t('user_list')}</li>
                            </ul>
                        </div>
                    </div>
                </Col>
                {floats.map((float, index) => (
                    <Col xl={4} key={ index }>
                        <FloatCardComponent
                            variant={ float.variant }
                            digit={ float.digit }
                            title={ float.title }
                            icon={ float.icon }
                        />
                    </Col>
                ))}
                <Col xl={12}>
                    <div className="mc-card">
                        <div className="mc-card-header">
                            <h4 className="mc-card-title">{t('registered_users')}</h4>
                            <Dropdown bsPrefix="mc-dropdown">
                                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle">
                                    <i className='material-icons'>more_horiz</i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>edit</i><span>{t('edit')}</span></button>
                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>delete</i><span>{t('delete')}</span></button>
                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>download</i><span>{t('download')}</span></button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Row xs={1} sm={2} xl={4}>
                            <Col>
                                <LabelFieldComponent
                                    label={t('show_by')}
                                    option={["12 row", "24 row", "36 row"]}
                                    labelDir="label-col"
                                    fieldSize="mb-4 w-100 h-md"
                                />
                            </Col>
                            <Col>
                                <LabelFieldComponent
                                    label={t('role_by')}
                                    option={["admin", "member", "client", "manager", "vendor"]}
                                    labelDir="label-col"
                                    fieldSize="mb-4 w-100 h-md"
                                />
                            </Col>
                            <Col>
                                <LabelFieldComponent
                                    label={t('status_by')}
                                    option={["approved", "pending", "blocked"]}
                                    labelDir="label-col"
                                    fieldSize="mb-4 w-100 h-md"
                                />
                            </Col>
                            <Col>
                                <LabelFieldComponent
                                    type="search"
                                    label={t('search_by')}
                                    placeholder={t('id') + ' / ' + t('name') + ' / ' + t('email') + ' / ' + t('number')}
                                    labelDir="label-col"
                                    fieldSize="mb-4 w-100 h-md"
                                />
                            </Col>
                        </Row>
                        <UsersTableComponent
                            thead={ users.thead }
                            tbody={ users.tbody }
                        />
                        <PaginationComponent />
                    </div>
                </Col>
            </Row>
        </PageLayout>
    );
}