const ordersStatusConfig = [
	{
		value: 'new',
		text: 'Nowe'
	},
	{
		value: 'in-progress',
		text: 'W trakcie realizacji'
	},
	{
		value: 'pending-payment ',
		text: 'Oczekuje na płatność '
	},
	{
		value: 'paid',
		text: 'Opłacone'
	},
	{
		value: 'preparing-for-shipment',
		text: 'Przygotowanie do wysyłki'
	},
	{
		value: 'shipped',
		text: 'Wysłane '
	},
	{
		value: 'delivered',
		text: 'Dostarczone'
	},
	{
		value: 'completed',
		text: 'Zakończone '
	},
	{
		value: 'cancelled',
		text: 'Anulowane '
	},
	{
		value: 'return',
		text: 'Zwrot'
	},
	{
		value: 'refunded',
		text: 'Zwrot środków'
	},
]

export default ordersStatusConfig;