import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { TranslatorContext } from "../../context/Translator";
import {LabelFieldComponent} from "../fields";
import {ButtonComponent} from "../elements";
import callToAPI from "../../api";
const AddPromotionForm = ({id}) => {
	const { t } = useContext(TranslatorContext);
	const initialData = {};
	const convertToTimestamp = (dateTime) => {
		return new Date(dateTime).getTime();
	};
	const handleSubmit = async values => {
		await callToAPI('/promotion/create', 'post', {
			promotionName: values.promotionName,
			userID: null,
			startAt: convertToTimestamp(values.startsAt),
			endAt: convertToTimestamp(values.endsAt),
			offerID: id,
			promotionPrice: values.promotionPrice
		})
	};

	return (
		<Formik initialValues={initialData} onSubmit={handleSubmit}>
			{({ values, handleSubmit, handleBlur, handleChange }) => (
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col xl={6}><LabelFieldComponent type="text" label={t('promotion name')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.promotionName} name="promotionName" /></Col>
						<Col xl={6}><LabelFieldComponent type="number" label={t('promotion price')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.promotionPrice} name="promotionPrice" /></Col>
						<Col xl={6}><LabelFieldComponent type="datetime-local" label={t('starts at')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.startsAt} name="startsAt" /></Col>
						<Col xl={6}><LabelFieldComponent type="datetime-local" label={t('ends at')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.endsAt} name="endsAt" /></Col>
						<ButtonComponent type="submit" className="btn btn-primary">{t('add')}</ButtonComponent>
					</Row>
				</Form>
			)}
		</Formik>
	);
}

export default AddPromotionForm;
