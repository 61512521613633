import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/Auth";
import {useNavigate} from "react-router-dom";
import {Outlet} from 'react-router-dom'
const AdminLayout = ({children}) => {
	const { user } = useContext(AuthContext);
	const navigate = useNavigate()
	const [isAdmin, setIsAdmin] = useState(false)

	useEffect(() => {
		if(user.accountType === 'admin'){
			setIsAdmin(true)
		}
	}, [user]);

	if(!isAdmin){
		navigate('/')
		return <Outlet />
	}
	return(
		<>
			<Outlet />
		</>
	)
}

export default AdminLayout;