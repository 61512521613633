const parametersConfig = {
	sex: [
		'female',
		'male',
		'unisex'
	],
	brand: [
		'lean',
		'bestway'
	]
}

export default parametersConfig;