const flattenObject = (ob, prefix = '') =>
	Object.keys(ob).reduce((toReturn, k) => {
		const pre = prefix.length ? prefix + '.' : '';
		if (typeof ob[k] === 'object' && ob[k] !== null && !Array.isArray(ob[k])) {
			Object.assign(toReturn, flattenObject(ob[k], pre + k));
		} else {
			toReturn[pre + k] = ob[k];
		}
		return toReturn;
	}, {});

export default flattenObject