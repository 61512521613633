import {createContext, useEffect, useState} from "react";
import callToAPI from "../api";
import useAuth from "../hooks/useAuth";
import Cookies from 'js-cookie'
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState()
	const auth = useAuth();
	useEffect(() => {
		callToAPI('/check-login', 'post')
			.then(res => setUser(res))
		let intervalId;
		if (auth) {
			intervalId = setInterval(async () => {
				let newToken = await callToAPI('/refresh-token', "POST", { token: Cookies.get("token") });
				await Cookies.set('token', newToken.token, {
					expires: 1 / 24
				});
			}, 1000 * 60 * 5);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [auth]);

	return (
		<AuthContext.Provider value={{ user, setUser }}>
			{ children }
		</AuthContext.Provider>
	)
}