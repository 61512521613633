import React, {useCallback, useContext, useRef, useEffect, useState} from "react";
import { TranslatorContext } from "../../context/Translator";
import {Link, useParams} from "react-router-dom";
import { Row, Col, Dropdown } from "react-bootstrap";
import { AnchorComponent, ButtonComponent } from "../../components/elements";
import { LabelFieldComponent, LabelTextareaComponent } from "../../components/fields";
import PageLayout from "../../layouts/PageLayout";
import {Formik} from "formik";
import { createReactEditorJS } from 'react-editor-js';
import {EDITOR_JS_TOOLS} from "../../helpers/constants";
import parametersConfig from "../../config/parametersConfig";
import {useDropzone} from "react-dropzone";
import callToAPI from "../../api";
import AddPromotionForm from "../../components/promotions/AddPromotionForm";
import flattenObject from "../../helpers/flattenObject";
import getImageUrl from "../../helpers/getImageUrl";
const ReactEditorJS = createReactEditorJS();
export default function ProductUploadPage() {

    const { t } = useContext(TranslatorContext);

    const id = useParams().id

    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([])
    const [imageFiles, setImageFiles] = React.useState([]);
    const [editorState, setEditorState] = useState(undefined)
    const imagesType = ['jpg', 'jpeg', 'png']
    const onDrop = useCallback(acceptedFiles => {
        setImageFiles(prevState => [...prevState, ...acceptedFiles.filter((el, index) => !prevState.find(item => item.name === el.name) && imagesType.indexOf(el.type.split('/')[1]) !== -1)])
    }, [])

    useEffect(() => {
        console.log(imageFiles)
    }, [imageFiles]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const [initialData, setInitialData] = useState( {
        offerID: id,
        title: '',
        parameter: {

        }
    })
    const handleSubmit = async (values) => {
        const fd = new FormData();

        const flattenedValues = flattenObject(values);
        for(const prop in flattenedValues) {
            fd.append(prop, flattenedValues[prop]);
        }

        imageFiles.forEach((file, index) => fd.append(`file-${index}`, file, file.name));

        console.log(fd); // Zobacz, co zostanie wysłane
        if(id){
            await callToAPI('/admin/offer/update', 'post', fd, 'json', true)
        }
        else{
            fd.append('createdTime', new Date().getTime());
            await callToAPI('/admin/offer/create', 'post', fd, 'json', true)
        }
    };

    const editorCore = useRef(null);

    const handleInitialize = (instance) => {
        editorCore.current = instance;
    }

    const getEditorValue = async (setFieldValue) => {
        const editorValue = await editorCore.current.save();
        setFieldValue('desc', JSON.stringify(editorValue.blocks));
    }

    const defaultEditorData = {
        time: new Date().getTime(),
        blocks: editorState !== undefined ? JSON.parse(editorState) : [
            {"type":"paragraph","data":{"text":"Twój początkowy tekst tutaj"}}
        ],
        version: "2.22.2",
    };

    useEffect(() => {
        callToAPI('/categories/get', 'get')
            .then(res => setCategories(res))
    }, []);

    useEffect(() => {
        if(id){
            setLoading(true)
            callToAPI(`/offer/get/${id}`, 'get')
                .then(res => {
                    setInitialData({offerID: id, ...res})
                    setEditorState(res.desc);
                    setImageFiles(() => res.imageNames.map(el => {
                        return {
                            type: 'storage',
                            src: getImageUrl(el)
                        }
                    }))
                    setLoading(false);
                })
        }
    }, [id]);

    const renderCategoryOptions = (categories, parentID = '0', level = 0) => {
        console.log(categories)
        return categories
            .filter(category => category.parentID === parentID)
            .map(category => (
                <React.Fragment key={category._id}>
                    <option value={category._id}>
                        {'-'.repeat(level) + ' ' + category.categoryName}
                    </option>
                    {renderCategoryOptions(categories, category._id, level + 1)}
                </React.Fragment>
            ));
    };

    return (
        <PageLayout>
            {loading ? 'Ładowanie' :
                <Formik initialValues={initialData} onSubmit={handleSubmit} enableReinitialize={true}>
                    {({
                          handleChange, handleBlur, values, handleSubmit, setFieldValue
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col xl={12}>
                                    <div className="mc-card mb-4">
                                        <div className='mc-breadcrumb'>
                                            <h3 className="mc-breadcrumb-title">{t('product_upload')}</h3>
                                            <ul className="mc-breadcrumb-list">
                                                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('home')}</Link></li>
                                                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('products')}</Link></li>
                                                <li className="mc-breadcrumb-item">{t('product_upload')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={7}>
                                    <div className="mc-card">
                                        <div className="mc-card-header">
                                            <h4 className="mc-card-title">{t('basic_information')}</h4>
                                            <Dropdown bsPrefix="mc-dropdown">
                                                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle">
                                                    <i className='material-icons'>more_horiz</i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>edit</i><span>{t('edit')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>delete</i><span>{t('delete')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>download</i><span>{t('download')}</span></button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <Row>
                                            <Col xl={12}><LabelFieldComponent type="text" label={t('title')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.title} name="title"/></Col>
                                            <Col xl={12}>
                                                <div className="container">
                                                    <ReactEditorJS
                                                        onInitialize={handleInitialize}
                                                        tools={EDITOR_JS_TOOLS}
                                                        defaultValue={defaultEditorData}
                                                    />
                                                    <button onClick={() => getEditorValue(setFieldValue)} type="button" className="btn btn-primary">
                                                        Dodaj opis
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col xl={6}><LabelFieldComponent type="number" label={t('ean_id')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.eanID} name="eanID"/></Col>
                                            <Col xl={6}><LabelFieldComponent type="number" label={t('price')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.price} name="price"/></Col>
                                            <Col xl={6}><LabelFieldComponent type="number" label={t('amount')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.amount} name="amount"/></Col>
                                            <Col xl={6}><LabelFieldComponent label={t('active')} option={['none','true', 'false']} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.active} name="active"/></Col>
                                            <Col xl={6}>
                                                <div className={`mc-label-field-group label-col`}>
                                                    <label className="mc-label-field-title">{ t('category') }</label>
                                                    <select style={{ backgroundImage: 'url(/images/dropdown.svg)' }} className={`mc-label-field-select mb-4 w-100 h-md`} onChange={handleChange} onBlur={handleBlur} value={values.categoryID} name="categoryID">
                                                        {renderCategoryOptions(categories)}
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xl={5}>
                                    <div className="mc-card mb-4">
                                        <div className="mc-card-header">
                                            <h4 className="mc-card-title">{t('promotions')}</h4>
                                            <Dropdown bsPrefix="mc-dropdown">
                                                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle">
                                                    <i className='material-icons'>more_horiz</i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>edit</i><span>{t('edit')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>delete</i><span>{t('delete')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>download</i><span>{t('download')}</span></button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="mc-card">
                                        <div className="mc-card-header">
                                            <h4 className="mc-card-title">{t('specification')}</h4>
                                            <Dropdown bsPrefix="mc-dropdown">
                                                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle">
                                                    <i className='material-icons'>more_horiz</i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>edit</i><span>{t('edit')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>delete</i><span>{t('delete')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>download</i><span>{t('download')}</span></button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <Row>
                                            <Col xl={6}><LabelFieldComponent type="number" label={t('age')} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.parameter.age} name="parameter.age"/></Col>
                                            <Col xl={6}><LabelFieldComponent label={t('sex')} option={['none', ...parametersConfig.sex]} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.parameter?.sex} name="parameter.sex" /></Col>
                                            <Col xl={6}><LabelFieldComponent label={t('brand')} option={['none', ...parametersConfig.brand]} fieldSize="mb-4 w-100 h-md" onChange={handleChange} onBlur={handleBlur} value={values.parameter?.brand} name="parameter.brand" /></Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xl={12}>
                                    <div className="mc-card">
                                        <div className="mc-card-header">
                                            <h4 className="mc-card-title">{t('media_and_published')}</h4>
                                            <Dropdown bsPrefix="mc-dropdown">
                                                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle">
                                                    <i className='material-icons'>more_horiz</i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>edit</i><span>{t('edit')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>delete</i><span>{t('delete')}</span></button>
                                                    <button type='button' className='mc-dropdown-menu'><i className='material-icons'>download</i><span>{t('download')}</span></button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <Row className="mc-product-upload-media d-flex">
                                            <div className="mc-product-upload-file" {...getRootProps()}>
                                                <input type="file" id="product" {...getInputProps()} multiple={true} accept={'image/*'} />
                                                <label htmlFor="product">
                                                    <i className="material-icons">collections</i>
                                                    <pan></pan>
                                                </label>
                                            </div>
                                            <Row>
                                                {imageFiles.map(el => {
                                                    return(
                                                        <Col xl={2} className="mc-product-upload-image">
                                                            {el.type === 'storage' ?
                                                                <img src={el.src} />
                                                                :
                                                                <img src={URL.createObjectURL(el)} />
                                                            }

                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Row>
                                        <ButtonComponent type="submit" title="Delete"  className="mc-btn w-100 primary mt-5"  icon="cloud_upload">{ t('publish_and_view') }</ButtonComponent>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
            }
        </PageLayout>
    )
}